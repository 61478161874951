import React from "react"

import Layout from "../components/layout/layout"
import MediaSection from "../components/sections/media-section"
import Seonew from "../components/seo-new"

const MediaPage = () => (
  <Layout>
    <Seonew title="Contact Us" />
    <MediaSection />
  </Layout>
)

export default MediaPage
