import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const MediaSection = () => (
  <div className="bg-blue-200 w-full md:min-h-screen xl:h-auto px-4 pt-24 md:pt-40 pb-24">
    <div className='w-full max-w-screen-xl flex flex-col items-center justify-center mx-auto'>
      <StaticImage
        src="../../images/logos/fw-new-logo-green-for-white-background.png"
        width="600"
        formats={["AUTO", "PNG"]}
      />
    </div>
  </div>
)

export default MediaSection